import React from "react";
import { styled, white, media, fonts } from "../../../styles";
import { ShadowedBox } from "../../../components";
import { StudioInterface } from "../StudioInterface";

interface IProps {
  studio: StudioInterface;
  angle?: number;
  zIndex?: number;
}

export const Studio: React.FC<IProps> = ({
  studio: {
    title,
    angle,
    bgAngle,
    bgPosition,
    right,
    video,
    alignment,
    mobileAdditionalMargin,
    mobileAdditionalTextMargin
  }
}) => {
  return (
    <StudioContainer
      angle={angle}
      bgAngle={bgAngle}
      bgPosition={bgPosition}
      alignment={alignment}
      mobileAdditionalMargin={mobileAdditionalMargin}
    >
      <StudioVideo
        autoPlay={true}
        loop={true}
        muted={true}
        playsInline={true}
        controls={false}
        preload="auto"
      >
        <source src={`/videos/studio/${video}-1440.mp4`} type="video/mp4" />
      </StudioVideo>

      <StudioTitle
        alignRight={right}
        alignment={alignment}
        mobileAdditionalMargin={mobileAdditionalMargin}
        mobileAdditionalTextMargin={mobileAdditionalTextMargin}
      >
        <img alt={title} src={require(`../assets/title_${video}.svg`)} />
      </StudioTitle>
    </StudioContainer>
  );
};

Studio.defaultProps = {
  angle: 0,
  zIndex: 0
};

const StudioContainer = styled(ShadowedBox).attrs({
  as: "section"
})<{
  alignment: "left" | "center" | "right";
  mobileAdditionalMargin?: number;
}>`
  width: 250%;
  margin-top: 2rem;
  margin-bottom: 2rem;

  margin-left: ${({ alignment, mobileAdditionalMargin }) => {
    if (alignment === "left") {
      return mobileAdditionalMargin
        ? `-${mobileAdditionalMargin + 20}%`
        : "-20%";
    } else if (alignment === "right") {
      return "-130%";
    } else {
      return "-110%";
    }
  }};

  ${media.landscape} {
    width: 120%;
    margin-left: -10%;
  }

  ${media.tablet} {
    width: 130%;
    margin-left: -15%;
  }

  ${media.laptop} {
    width: 120%;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  ${media.desktop} {
    width: 110%;
    margin-left: -10%;
  }

  @media (min-width: 1440px) {
    width: 110%;
    margin-left: -5%;
  }

  ${media.hd} {
    margin-left: -5%;
  }
`;

const StudioVideo = styled.video`
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  vertical-align: middle;
`;

const StudioTitle = styled.div<{
  alignRight?: boolean;
  alignment: "left" | "center" | "right";
  mobileAdditionalTextMargin?: number;
}>`
  position: absolute;
  bottom: 0;
  left: ${({ mobileAdditionalTextMargin }) =>
    mobileAdditionalTextMargin ? `${mobileAdditionalTextMargin}` : 0}%;
  right: 0;
  width: 100%;
  white-space: nowrap;
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  font-variation-settings: "wght" 520;
  letter-spacing: 0 !important;
  text-align: ${({ alignment }) => alignment};
  color: ${white};
  margin-bottom: 0;

  font-family: ${fonts.vario};
  font-weight: 700;
  font-size: 20px;

  padding: 3% 5%;

  img {
    max-height: 35px;
    max-width: fit-content;
    width: auto;
  }

  ${media.landscape} {
    text-align: ${({ alignRight }) => (alignRight ? "right" : "left")};
    padding: 5% 10%;
    left: 0;

    img {
      max-height: 30px;
    }
  }

  ${media.tablet} {
    font-size: 20px;
    text-align: ${({ alignRight }) => (alignRight ? "right" : "left")};
    padding: 5% 12%;
    left: 0;

    img {
      max-height: 40px;
    }
  }

  ${media.laptop} {
    font-size: 30px;
    padding: 5% 5% 5% 15%;

    img {
      max-height: 50px;
    }
  }

  ${media.desktop} {
    font-size: 34px;
    padding: 5% 7%;
  }
`;
