import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Trans } from "@lingui/macro";
import { styled, media, angle } from "../../styles";
import { smoothScrollToAnchor } from "../../utils";
import {
  Page,
  Content,
  TitleImage,
  Link,
  Accordeon,
  AccordeonItem,
  AccordeonTitle,
  AccordeonBody
} from "../../components";
import { Studio } from "./components";
import { StudioInterface } from "./StudioInterface";
import headerImg from "./assets/Illu_Studio.svg";

export const StudioPage: React.FC<{}> = props => {
  const {
    studios: { edges: studioNodes }
  }: {
    studios: {
      edges: [
        {
          node: StudioInterface;
        }
      ];
    };
  } = useStaticQuery(graphql`
    query studiosQuery {
      studios: allStudiosJson {
        edges {
          node {
            id
            title
            description {
              de
              en
            }
            alignment
            video
            vimeoId
            angle
            bgAngle
            bgPosition
            right
            floor
            mobileAdditionalMargin
            mobileAdditionalTextMargin
          }
        }
      }
    }
  `);

  return (
    <Page
      title="Studio"
      seoTitle="Musikstudio | Digital Audio Unit | German Wahnsinn"
      description="Hört euch das an: ob Audio-Postproduction, Digital Audio Unit, Music Recording oder Hörspiel- und Hörbuch-Produktionen – alles da!"
      keywords={[
        "German Wahnsinn",
        "Tonstudio",
        "Musikstudio",
        "Aufnahmestudio",
        "Audio-Postproduction",
        "Digital Audio Unit",
        "Music Recording",
        "Hörspiel-Produktionen",
        "Hörbuch-Produktionen"
      ]}
      bgPaper="square"
      headerSkull="Studio"
      {...props}
    >
      <Content>
        <StudioIntroContainer>
          <StudioTitleImage title="Studio" src={headerImg} />

          <StudioCopy>
            <Trans>
              <strong>KÖNNEN SIE DAS HÖREN?</strong>
              <br />
              (falls nicht, gibt es auch{" "}
              <a href="#rooms" onClick={smoothScrollToAnchor}>
                Fotos
              </a>
              !)
              <br />
              <br />
              Das ist unser Studio. Zehn Räume vollgepackt mit Wissen, Dingen
              und Persönlichkeiten, mit denen wir die gesamte Klaviatur von
              Recording, Editing, Mixing und Mastering von Musik, Sprache und
              Geräuschen bespielen können.
              <br />
              Ob mono, stereo, 3D Audio oder 7.1.4 Dolby Atmos - herein mit dir, lass dich in eine warme Decke aus liebevoller
              Dienstleistung hüllen.
              <br />
              Es folgen unsere wohlklingenden Fachbereiche.
            </Trans>
          </StudioCopy>
        </StudioIntroContainer>

        <StudioAccordeonContainer>
          <Accordeon>
            <AccordeonItem expanded={true}>
              <AccordeonTitle>
                <Trans>Audio-Postproduktion</Trans>
              </AccordeonTitle>
              <AccordeonBody>
                <Trans>
                  Für{" "}
                  <Link to="/work/commercials/">
                    Werbeagenturen und Filmproduktionen
                  </Link>{" "}
                  realisieren wir <Link to="/music/">Musik</Link>,{" "}
                  <Link to="/work/sound-design/">Sounddesign</Link>,{" "}
                  <Link to="http://sprechergesucht.germanwahnsinn.de">
                    Sprechercastings
                  </Link>{" "}
                  und Sprachaufnahmen. Natürlich sind wir weltweit tiptop
                  vernetzt mit Partnerstudios via VoIP mit Source Connect oder
                  Session Link.
                </Trans>
              </AccordeonBody>
            </AccordeonItem>

            <AccordeonItem>
              <AccordeonTitle>
                <Trans>Immersive Audio</Trans>
              </AccordeonTitle>
              <AccordeonBody>
                <Trans>
                  Hier klopft die Zukunft ans Trommelfell und will hinein: Seit
                  Jahren beschäftigen wir uns mit der Zukunft von Audio und
                  produzieren in einer eigenen Unit digitalen Audio-Content für
                  Mobile Games,{" "}
                  <Link to="/work/arvrtechnology/"> XR/VR/AR-Anwendungen</Link> und
                  Chatbots. Egal ob FMod, Wwise oder eigene Plugin Lösungen –
                  wir wissen, wo oben und wo unten ist.
                </Trans>
              </AccordeonBody>
            </AccordeonItem>

            <AccordeonItem>
              <AccordeonTitle>
                <Trans>Music Recording</Trans>
              </AccordeonTitle>
              <AccordeonBody>
                <Trans>
                  Das Herz unseres Studios schlägt in unserem 56 qm großen
                  Aufnahmeraum, ausgestattet mit einem Bechstein-Flügel, Amps
                  und allem anderen Zipp und Zapp. Er freut sich 24/7 auf jede
                  Art von Sound. Musiker, Bands und Ensembles können unsere
                  Räume für Sessions, Live-Recordings, Bandcamps oder Rehearsals
                  nutzen, a whole lotta love inklusive.
                </Trans>
              </AccordeonBody>
            </AccordeonItem>

            <AccordeonItem>
              <AccordeonTitle>
                <Trans>Hörspiel- und Hörbuch-Produktion</Trans>
              </AccordeonTitle>
              <AccordeonBody>
                <Trans>
                  Sensationell gute Geschichten – ob spannend, gruselig, lustig
                  oder fantastisch – verdienen einen individuellen und
                  exklusiven Sound, der dir die Schuhe auszieht. Mit dieser
                  Überzeugung haben wir schon viele bekannte Stoffe zum Leben
                  erweckt. Da Storytelling keine Einbahnstraße ist und wir gerne
                  gemeinsam kreativ sind, haben wir 2016 mit Deutschlands
                  erfolgreichster Kinderbuch-Autorin Cornelia Funke einen
                  eigenen Audiobook-Verlag gegründet. Mit{" "}
                  <Link to="https://www.atmende-buecher.de/">
                    Atmende Bücher
                  </Link>{" "}
                  setzen wir exklusiv ihre Geschichten um.
                </Trans>
              </AccordeonBody>
            </AccordeonItem>
          </Accordeon>
        </StudioAccordeonContainer>

        <StudiosList id="rooms">
          {studioNodes.map(({ node: studio }) => (
            <Studio key={studio.title} studio={studio} />
          ))}
        </StudiosList>
      </Content>
    </Page>
  );
};

const StudioTitleImage = styled(TitleImage)`
  width: 100%;
  margin: 0 0 2rem -1rem;

  ${media.tablet} {
    width: 60%;
    margin: 0 1.5rem;
  }

  ${media.landscape} {
    width: 50%;
    margin: 0 2rem;
  }

  ${media.laptop} {
    width: 50%;
  }
`;

const StudioIntroContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  ${media.laptop} {
    flex-direction: row;
  }
`;

const StudioCopy = styled.p`
  flex: 1;
  width: 100%;
  margin: 0 0 1.5rem 0;

  ${media.tablet} {
    width: 62.5%;
    margin: 4rem 0 4rem 37.5%;

    ${angle(7)};
  }

  ${media.landscape} {
    width: 100%;
    max-width: 83.3333%;
    margin: 2rem auto;

    ${angle(0)};
  }

  ${media.laptop} {
    width: 41.6667%;
    margin: 8rem 0 4rem 0;
    ${angle(5)};
  }
`;

const StudioAccordeonContainer = styled.div`
  display: flex;
  margin: 0 0 1rem 0;

  ${media.tablet} {
    margin: 0 12.5% 3rem 12.5%;
  }

  ${media.laptop} {
    margin: 0 12.5% 3rem 25%;
  }

  ${media.hd} {
    margin: 0 12.5% 6rem 25%;
  }
`;

const StudiosList = styled.div`
  display: flex;
  flex-direction: column;
`;
